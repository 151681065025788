.portfolio__container {
  grid-template-columns: repeat(6, 1fr);
  gap: 2.5rem;
  display: flex;
  /* justify-content: center; */
  overflow: auto; /* This will add scrollbars if content overflows */
}

.portfolio__item {
  background: var(--color-bg-variant);
  padding: 1.3rem;
  min-width: 350px;
  border-radius: 1rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.portfolio__item:hover {
  border-color: var(--color-primary);
  background: transparent;
}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__item h3 {
  margin: 1.2rem 0 2rem;
}

.portfolio__item h5 {
  margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

/*=============== MEDIA QUERIES ===============*/

/*========== Medium devices ==========*/
@media screen and (max-width: 1024px) {
  .protfolio__container {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.2rem;
    display: grid;
  }
}

/*========== Small devices ==========*/
@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
    display: grid;
  }
}
