.content{
    display: column;
    margin-top: 6rem;
    margin-bottom: 4rem;
    justify-content: center;
    justify-items: center;
}

.title{
    text-align: center;
    color: var(--color-primary);

}

.contimg{
    display: flex;
    justify-content: center;    
    margin-bottom: 25px;
}

.img{

    width: 90dvw;
}
