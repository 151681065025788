.all{
    width: 100dvw;
    height: 100dvh;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.ti{
    background-color: aqua;
}